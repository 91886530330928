import moment from 'moment';
import { Reducer } from 'redux';

import {
  ACCOUNT_DETAILS_ONLY,
  DATE_FORMAT,
  LONG_DATE_FORMAT,
} from '../../shared/constants';
import {
  CustomerStore,
  CustomerStoreAction,
  CustomerStoreActionTypes,
  Owner,
} from './customerTypes';

const initialState: CustomerStore = {
  owners: null,
  careGivers: null,
  error: null,
  isLoading: false,
  createUserSuccessful: false,
  careTeam: null,
  careTeamDetails: null,
  facilityCustomers: null,
  didAddPrimaryContact: false,
  selectedTab: ACCOUNT_DETAILS_ONLY.ACCOUNT_DETAILS,
  careteamInvites: null,
  currentOwnerPin: null,
  deleteUserSuccessful: false,
};

const customerReducer: Reducer<CustomerStore, CustomerStoreAction> = (
  state = initialState,
  action
): CustomerStore => {
  switch (action.type) {
    case CustomerStoreActionTypes.POPULATE_CUSTOMERS: {
      const { customerList } = action.payload;

      let owners: Owner[] = [];
      let careGivers: Owner[] = [];

      customerList.forEach((customer) => {
        if (customer.is_patient) owners.push(customer);
        else careGivers.push(customer);
      });
      owners.forEach((owner) => {
        let dob = moment(owner.date_of_birth);
        owner.date_of_birth = dob.isValid() ? dob.format(DATE_FORMAT) : '-';
        owner.name = `${owner.first_name} ${owner.last_name}`;
      });
      careGivers.forEach((careGiver) => {
        let dob = moment(careGiver.date_of_birth);
        careGiver.date_of_birth = dob.isValid() ? dob.format(DATE_FORMAT) : '-';
        careGiver.name = `${careGiver.first_name} ${careGiver.last_name}`;
      });
      let newState = {
        ...state,
        owners,
        careGivers,
        facilityCustomers: customerList,
      };
      return newState;
    }
    case CustomerStoreActionTypes.CUSTOMERS_API_ERR: {
      const { error } = action.payload;
      let newState = {
        ...state,
        error: error,
      };
      return newState;
    }
    case CustomerStoreActionTypes.CUSTOMERS_SET_LOAD_STATE: {
      const { isLoading } = action.payload;
      let newState = {
        ...state,
        didAddPrimaryContact: false,
        isLoading: isLoading,
      };
      return newState;
    }
    case CustomerStoreActionTypes.SUCCESS_CREATE_USER: {
      const { createUserSuccessful } = action.payload;
      let newState = {
        ...state,
        createUserSuccessful: createUserSuccessful,
      };
      return newState;
    }
    case CustomerStoreActionTypes.POPULATE_CUSTOMER: {
      const { customer } = action.payload;
      const { facilityCustomers } = state;
      let is_online = false;

      if (facilityCustomers) {
        const socketCustomer = facilityCustomers.find(
          ({ customer_id }) => customer_id === customer.customer_id
        );
        if (socketCustomer) {
          is_online = !!socketCustomer.is_online;
        }
      }

      let dob = moment(customer.date_of_birth);
      let newFacilityCustomers = (state.facilityCustomers || []).filter(
        ({ customer_id }) => customer_id !== customer.customer_id
      );
      let newState = {
        ...state,
        facilityCustomers: [
          ...newFacilityCustomers,
          {
            ...customer,
            long_format_dob: dob.isValid() ? dob.format(LONG_DATE_FORMAT) : '-',
            is_online,
            name: `${customer.first_name} ${customer.last_name}`,
          },
        ],
      };
      return newState;
    }
    case CustomerStoreActionTypes.POPULATE_CUSTOMER_CARE_TEAM: {
      const { careTeam } = action.payload;

      const filteredCareTeam = Object.entries(careTeam).reduce((acl, curr) => {
        const [customerId, careTeam] = curr;
        return {
          ...acl,
          [customerId]: careTeam.filter((a) => a.careteam_role_id),
        };
      }, {});
      let newState = {
        ...state,
        careTeam: { ...state.careTeam, ...filteredCareTeam },
      };
      return newState;
    }
    case CustomerStoreActionTypes.CLEAR_CUSTOMER_AND_CARE_TEAM: {
      let newState = {
        ...state,
        careTeam: initialState.careTeam,
        careTeamDetails: initialState.careTeamDetails,
        currentOwnerPin: initialState.currentOwnerPin,
      };
      return newState;
    }
    case CustomerStoreActionTypes.CREATE_PRIMARY_CONTACT_SUCCESS: {
      let newState = {
        ...state,
        isLoading: false,
        didAddPrimaryContact: true,
      };
      return newState;
    }
    case CustomerStoreActionTypes.POPULATE_CARE_TEAM_DETAILS: {
      const { careTeamDetails } = action.payload;
      const { facilityCustomers = [] } = state;
      let is_online = false;
      if (facilityCustomers) {
        const socketCustomer = (facilityCustomers || []).find(
          ({ customer_id }) => customer_id === careTeamDetails.customer_id
        );
        if (socketCustomer) {
          is_online = !!socketCustomer.is_online;
        }
      }

      let newState = {
        ...state,
        careTeamDetails: {
          ...careTeamDetails,
          is_online,
        },
      };
      return newState;
    }
    case CustomerStoreActionTypes.CLEAR_CARE_TEAM_DETAILS:
      return {
        ...state,
        careTeamDetails: null,
      };

    case CustomerStoreActionTypes.POPULATE_CARETEAM_INVITES: {
      const { careteamInvites } = action.payload;
      let newState = {
        ...state,
        careteamInvites,
      };
      return newState;
    }
    case CustomerStoreActionTypes.CUSTOMERS_SIDE_BAR_TAB_SELECTION: {
      const { selectedTab } = action.payload;
      let newState = {
        ...state,
        selectedTab: selectedTab,
      };
      return newState;
    }
    case CustomerStoreActionTypes.SAVE_OWNER_PIN: {
      const { ownerPin } = action.payload;
      let newState = {
        ...state,
        currentOwnerPin: ownerPin,
      };
      return newState;
    }
    case CustomerStoreActionTypes.SUCCESS_DELETE_USER: {
      const { deleteUserSuccessful } = action.payload;
      return {
        ...state,
        deleteUserSuccessful,
      };
    }

    default:
      return state;
  }
};

export default customerReducer;
