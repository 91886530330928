import { DateTime } from 'luxon';
import { MedEvent } from '../../components/Shared/ImportMedicationRollStepFormTypes';
import { LoadScheduleMatchItem, MedEventType } from '../../generated';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

export enum HubOwnerStoreActionTypes {
  CREATE_MEDICATION_ROLL_SUCCESS = 'CREATE_MEDICATION_ROLL_SUCCESS',
  GET_MEDICATION_SCHEDULE_TEMPLATE = 'GET_MEDICATION_SCHEDULE_TEMPLATE',
  RESET_IMPORT_MED_ROLL_FORM = 'RESET_IMPORT_MED_ROLL_FORM',
  GET_MEDICATION_SCHEDULE = 'GET_MEDICATION_SCHEDULE',
  GET_MEDICATION_EVENT_HISTORY = 'GET_MEDICATION_EVENT_HISTORY',
  SET_LOADING = 'SET_LOADING',
  UNSET_LOADING = 'UNSET_LOADING',
  FETCH_HUB_OWNERS_SUCCESS = 'FETCH_HUB_OWNERS_SUCCESS',
  ADD_HUB_OWNER_SUCCESS = 'ADD_HUB_OWNER_SUCCESS',
  RESET_ADD_HUB_OWNER_SUCCESS = 'RESET_ADD_HUB_OWNER_SUCCESS',
  EDIT_OWNER_SUCCESS = 'EDIT_OWNER_SUCCESS',
}

export type CreateMedicationRollSuccessAction = {
  type: HubOwnerStoreActionTypes.CREATE_MEDICATION_ROLL_SUCCESS;
};

export type GetMedicationScheduleTemplateAction = {
  type: HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE_TEMPLATE;
  payload: { medScheduleTemplate: CreateMedRollTemplate };
};

export type GetMedicationScheduleAction = {
  type: HubOwnerStoreActionTypes.GET_MEDICATION_SCHEDULE;
  payload: { medSchedule: LoadScheduleMatchItem[] };
};

export type GetMedicationEventHistoryAction = {
  type: HubOwnerStoreActionTypes.GET_MEDICATION_EVENT_HISTORY;
  payload: {
    ownerId: number;
    medEventId: string;
    medEventHistory: MedEventHistory;
  };
};

export type ResetImportMedRollFormAction = {
  type: HubOwnerStoreActionTypes.RESET_IMPORT_MED_ROLL_FORM;
};

export type HubOwnerStore = {
  medScheduleTemplate?: CreateMedRollTemplate;
  importMedicationRollSuccess: boolean;
  medSchedule?: LoadScheduleMatchItem[];
  medEventHistory?: MedEventHistoryState;
  hubOwners?: HubOwner[];
  addHubOwnerSuccess: boolean;
  isLoading: boolean;
};

export type CreateMedRollTemplate = {
  medEvents: MedEvent[];
  date?: DateTime;
  batchId?: Number;
};

export type SetLoadingAction = {
  type: HubOwnerStoreActionTypes.SET_LOADING;
};

export type UnsetLoadingAction = {
  type: HubOwnerStoreActionTypes.UNSET_LOADING;
};

export type FetchHubOwnersSuccessAction = {
  type: HubOwnerStoreActionTypes.FETCH_HUB_OWNERS_SUCCESS;
  payload: HubOwner[];
};

export type AddHubOwnerSuccessAction = {
  type: HubOwnerStoreActionTypes.ADD_HUB_OWNER_SUCCESS;
  payload: { message: string };
};

export type ResetAddHubOwnerSuccessAction = {
  type: HubOwnerStoreActionTypes.RESET_ADD_HUB_OWNER_SUCCESS;
};

export type EditOwnerSuccessAction = {
  type: HubOwnerStoreActionTypes.EDIT_OWNER_SUCCESS;
  payload: { ownerId: number; requestBody: EditOwnerReqParams };
};

export type HubOwnerStoreAction =
  | CreateMedicationRollSuccessAction
  | GetMedicationScheduleTemplateAction
  | ResetImportMedRollFormAction
  | GetMedicationScheduleAction
  | SetLoadingAction
  | UnsetLoadingAction
  | GetMedicationEventHistoryAction
  | FetchHubOwnersSuccessAction
  | AddHubOwnerSuccessAction
  | ResetAddHubOwnerSuccessAction
  | EditOwnerSuccessAction;

export interface CreateMedicationRollPost {
  customerId: number;
  batchId: number;
  pharmacyId: number;
  startDate: DateTime;
  scheduleTemplate: MedicationEvent[];
  scheduleTemplateDuration: number;
}

export interface MedicationEvent {
  prescribedTimeOfDay24: DateTime;
  desiredTimeOfDay24: DateTime;
  eventType?: MedEventType;
  pouches: Pouch[];
}

export interface Pouch {
  pouchNumber: number;
  drugs: Drug[];
}

export interface Drug {
  nationalDrugCode: string;
  rollPackaged: boolean;
  isPrn: boolean;
}

export interface MedicationSchedule {
  schedule_local_timezone: string;
  schedule_received_at: DateTime;
  upcoming_med_event_count: number;
  past_med_event_count: number;
  total_med_event_count: number;
  passed_due_dose_count: number;
  passed_due_pouch_count: number;
  med_rolls: MedicationRoll[];
  schedule: ScheduleMedicationEvent[];
}

export interface MedicationRoll {
  batch_id: number;
  starting_pouch_number: number;
  end_pouch_number: number;
  number_of_pouches: number;
  start_datetime: DateTime;
  end_datetime: DateTime;
}

export interface ScheduleMedicationEvent {
  prescribed_datetime: DateTime;
  desired_datetime: DateTime;
  prescribed_time_of_day_24: string;
  desired_time_of_day_24: string;
  dose_count: number;
  pouch_count: number;
  event_type: string;
  pouches: SchedulePouch[];
}

export interface SchedulePouch {
  pouch_number: number;
  batch_id: number;
  pouch_id: string;
  dose_count: number;
  status_counts: Map<string, number>;
  doses: ScheduleDose[];
}

export interface ScheduleDose {
  dose_id: number;
  national_drug_code: string;
  modified_at: DateTime;
  drug_name: string;
  tablet_count: string;
  dispensed_at: DateTime;
  medication_dose_status_id: number;
  administered_at: DateTime;
  administered_by: string;
  administered_timezone: string;
  received_at: DateTime;
  status: string;
}

export type MedEventHistoryState = {
  ownerId: number;
  medEventId: string;
  history: MedEventHistory;
};

export type HubOwner = {
  customer_id: number;
  first_name: string;
  last_name: string;
  gender: string;
  gender_id: number;
  age: number;
  phone: string;
  email: string;
  date_of_birth: string;
  relationship_to_owner: string;
  device_id: number;
  facility_id: number;
  facility_name: string;
  sole_relationship: boolean;
  med_status: string;
  next_med_event: string;
};

export interface AddHubOwnerParams {
  first_name: string;
  last_name: string;
  phone: string;
  gender_id: number;
  date_of_birth: string;
  email: string;
  med_window_time_sec?: number;
  device_id?: number;
  address1?: string;
  city?: string;
  state?: string;
  zipcode?: string;
}

export interface EditOwnerReqParams {
  first_name?: string;
  last_name?: string;
  phone: string;
  address1: string;
  city?: string;
  state?: string;
  zipcode?: string;
  gender_id?: number;
  date_of_birth?: string;
  med_window_time_sec?: number;
}

interface TableStatusInfo {
  icon: IconDefinition;
  color: string;
  text: string;
}

export interface CustomerCareHubRow {
  id: number;
  assigned: TableStatusInfo;
  facility_name: string;
  status: TableStatusInfo;
  software_version: string;
  hub_serial_number: string;
  action: IconDefinition;
}

export type MedEventHistory = Array<{
  /**
   * The prescribed datetime of the medication event
   */
  med_event_id: string;
  /**
   * Status ID of the medication dose
   */
  status_id: number;
  /**
   * The timestamp when the medication dose status change occurred
   */
  occured_at: string;
  /**
   * The timezone of the owners hub
   */
  device_time_zone: string;
  event_data?: {
    dispensed_flow?: string;
    'skipped_reasons?'?: Array<string>;
  };
  sent_by: {
    /**
     * The ID of the customer
     */
    customer_id: number;
    /**
     * The first name of the customer
     */
    customer_first_name: string;
    /**
     * The last name of the customer
     */
    customer_last_name: string;
  };
  /**
   * Array of medication doses associated with the same occurrence time
   */
  doses: Array<{
    /**
     * The ID of the medication dose
     */
    id: number;
    /**
     * The national drug code for the medication
     */
    national_drug_code: string;
    /**
     * The name of the medication
     */
    drug_name: string;
  }>;
}>;
