import { Box, Button, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { lightBackground1 } from '../../mainTheme';
import { useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import LoadingSpinner from '../Shared/LoadingSpinner';
import { faCircleCheck } from '@fortawesome/pro-solid-svg-icons';
import { faTimes } from '@fortawesome/pro-light-svg-icons';
import { fetchDeviceById } from '../../store/devices/actionCreators';
import StatusChip from '../Shared/StatusChip';
import { parseDateToLocal } from '../../shared/utils';
import { Status } from '../../shared/constants';

const baseStyle = {
  color: '#000',
  fontStyle: 'normal',
  fontWeight: 500,
  lineHeight: '24px',
  letterSpacing: '0.2px',
};

const headingStyle = {
  ...baseStyle,
  fontSize: '22px',
  lineHeight: '30px',
  letterSpacing: '0.36px',
};

const headLineStyle = {
  ...baseStyle,
  fontSize: '18px',
};

const contentStyle = {
  ...baseStyle,
  color: 'var(--Font-F3---Body, #585863)',
  fontSize: '16px',
  paddingBottom: 2,
};

const HubProfile = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { id } = useParams<{ id: string }>();

  const { selectedDevice, isLoading } = useSelector(
    (state: RootState) => state.devices
  );

  const isOnline =
    selectedDevice?.device_status === Status?.Online.toLowerCase();

  useEffect(() => {
    if (id) {
      dispatch(fetchDeviceById(Number(id)));
    }
  }, [id, dispatch]);

  const handleBackClick = () => {
    history.goBack();
  };

  if (isLoading || !selectedDevice) {
    return <LoadingSpinner />;
  }

  return (
    <Box
      display="flex"
      height="100%"
      flexDirection="column"
      bgcolor={lightBackground1}
      p={2}
    >
      <Box>
        <Button
          variant="text"
          color="secondary"
          onClick={handleBackClick}
          data-testid="back-button"
        >
          Back
        </Button>
      </Box>

      <Box py={2}>
        <Typography sx={headingStyle}>
          Hub #{selectedDevice?.serial_number}
        </Typography>
      </Box>

      <Box>
        <Box display="flex" alignItems="baseline" pb={2}>
          <Typography sx={headLineStyle}>Hub details</Typography>
        </Box>
        <Box display="flex" gap={5}>
          <Box display="flex" flexDirection="column">
            <Box>
              <Typography sx={headLineStyle}>Status</Typography>
              <Typography sx={contentStyle} py={1}>
                <StatusChip
                  icon={isOnline ? faCircleCheck : faTimes}
                  iconColor={isOnline ? '#42AF60' : 'red'}
                  labelText={isOnline ? Status.Online : Status.Offline}
                  conditionalValue={isOnline ? true : false}
                />
              </Typography>
            </Box>
            <Box>
              <Typography sx={headLineStyle}>Serial #</Typography>
              <Typography sx={contentStyle}>
                {selectedDevice?.serial_number}
              </Typography>
            </Box>
          </Box>
          <Box display="flex" flexDirection="column">
            {!isOnline && (
              <Box>
                <Typography sx={headLineStyle}>Last seen online</Typography>
                <Typography sx={contentStyle} py={2}>
                  {selectedDevice?.last_seen_online
                    ? parseDateToLocal(
                        selectedDevice?.last_seen_online,
                        'h:mm a - MM/dd/yy'
                      )
                    : 'No data'}
                </Typography>
              </Box>
            )}
            <Box>
              <Typography sx={headLineStyle}>Software version</Typography>
              <Typography sx={contentStyle}>
                {selectedDevice?.software_version}
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography sx={headLineStyle}>Assigned to</Typography>
        <Typography sx={contentStyle}>
          {selectedDevice?.first_name && selectedDevice?.last_name
            ? `${selectedDevice.first_name} ${selectedDevice.last_name}`
            : 'No data'}
        </Typography>

        <Typography sx={headLineStyle}>Assigned Location</Typography>
        <Typography sx={contentStyle}>
          {selectedDevice?.facility_name
            ? selectedDevice.facility_name
            : 'No data'}
        </Typography>

        <Typography sx={headLineStyle}>Last assigned</Typography>
        <Typography sx={contentStyle}>
          {selectedDevice?.device_synced_at
            ? parseDateToLocal(
                selectedDevice?.device_synced_at,
                'EEE MMM dd yyyy'
              )
            : 'No data'}
        </Typography>
      </Box>
    </Box>
  );
};

export default HubProfile;
