import {
  Box,
  Button,
  IconButton,
  MenuItem,
  SelectChangeEvent,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { lightBackground1 } from '../../../mainTheme';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faBarsFilter,
  faPenToSquare,
  faTimes,
  faCircleUser,
} from '@fortawesome/pro-light-svg-icons';
import NewModal from '../../Shared/NewModal';
import { faCircleCheck, faWifiSlash } from '@fortawesome/pro-solid-svg-icons';
import { FormikProps } from 'formik';
import { useHistory } from 'react-router-dom';
import DataGridTable from '../../Shared/DataGridTable/DataGridTable';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../store';
import StatusChip from '../../Shared/StatusChip';
import { getDeviceList } from '../../../store/devices/actionCreators';
import { Device } from '../../../store/devices/deviceTypes';
import StyledSelect from '../../Shared/StyledSelect';
import { CustomerCareHubRow } from '../../../store/hubOwner/types';
import CustomerHubsFilterForm from './CustomerHubFilters';
import { Status } from '../../../shared/constants';

interface FacilityOption {
  label: string;
  value: string;
}

const ActionCell = ({ deviceId }: { deviceId: number }) => {
  const history = useHistory();
  const handleNavigation = () => {
    history.push(`/v2/hubs/${deviceId}`);
  };

  return (
    <Box display="flex" justifyContent="flex-end" width="100%">
      <IconButton size="small" onClick={handleNavigation}>
        <FontAwesomeIcon
          icon={faPenToSquare}
          style={{ marginRight: 16 }}
          size="sm"
        />
      </IconButton>
    </Box>
  );
};

const stateSelector = (state: RootState) => {
  const { devices } = state;
  const { deviceList, isLoading: isDeviceListLoading } = devices;

  return {
    deviceList,
    isDeviceListLoading,
  };
};

const columns = [
  {
    field: 'assigned',
    headerName: 'Assigned',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <StatusChip
          icon={params.value.icon}
          iconColor={params.value.color}
          labelText={params.value.text}
          conditionalValue={params.value.text !== 'Unassigned' ? true : false}
          backgroundColor=" var(--Background-B2---Container, #F6F6F9)"
        />
      </Box>
    ),
  },
  { field: 'facility_name', headerName: 'Location', flex: 1 },
  {
    field: 'status',
    headerName: 'Status',
    flex: 1,
    renderCell: (params: any) => (
      <Box display="flex" alignItems="center">
        <StatusChip
          icon={params.value.icon}
          iconColor={params.value.color}
          labelText={params.value.text}
          conditionalValue={params.value.text === Status.Online ? true : false}
        />
      </Box>
    ),
  },
  { field: 'software_version', headerName: 'Software version', flex: 1 },

  { field: 'hub_serial_number', headerName: 'Serial number', flex: 1 },
  {
    field: 'action',
    headerName: 'Actions',
    renderCell: (params: any) => <ActionCell deviceId={params.row.id} />,
  },
];

const CustomerHubOverview = () => {
  const dispatch = useDispatch();

  const { deviceList, isDeviceListLoading } = useSelector<
    RootState,
    ReturnType<typeof stateSelector>
  >(stateSelector);

  useEffect(() => {
    if (!deviceList) {
      dispatch(getDeviceList());
    }
  }, [deviceList, dispatch]);

  const rows: CustomerCareHubRow[] = useMemo(
    () =>
      deviceList?.map((device: Device) => {
        const isOnline = device.device_status === Status.Online.toLowerCase();
        const hasOwner = device?.first_name && device?.last_name;

        return {
          id: device.id,
          assigned: {
            icon: hasOwner ? faCircleUser : faWifiSlash,
            color: hasOwner ? '#585863' : '#DB5543',
            text: hasOwner
              ? `${device?.first_name} ${device?.last_name}`
              : 'Unassigned',
          },
          facility_name: device.facility_name || 'No data',
          status: {
            icon: isOnline ? faCircleCheck : faTimes,
            color: isOnline ? '#42AF60' : 'red',
            text: isOnline ? Status.Online : Status.Offline,
          },
          software_version: device.software_version || 'No data',
          hub_serial_number: device?.serial_number,
          action: faBarsFilter,
        };
      }) ?? [],
    [deviceList]
  );

  const [isFilterModalOpen, setIsFilterModalOpen] = useState(false);
  const [selectedFacility, setSelectedFacility] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    hub_assigned: '',
    hub_status: '',
    software_version: '',
  });

  const formRef = useRef<
    FormikProps<{
      hub_assigned: string;
      hub_status: string;
      software_version: string;
    }>
  >(null);

  const handleContinue = () => {
    if (formRef.current) {
      formRef.current.submitForm();
    }
  };

  const onSubmitFilterForm = (values: any) => {
    if (formRef.current) {
      setFilters(values);
      setIsFilterModalOpen(false);
    }
  };

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleFacilityChange = (event: SelectChangeEvent<string>) => {
    const value = event.target.value as string;
    setSelectedFacility(value);
  };

  const filteredData = rows?.filter((row) => {
    const matchesSearchTerm =
      row.assigned.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.status.text.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.facility_name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      row.hub_serial_number.toLowerCase().includes(searchTerm.toLowerCase());
    row.hub_serial_number.toLowerCase().includes(searchTerm.toLowerCase());
    console.log(filters.software_version);
    const matchesFilters =
      (filters.hub_assigned === '' ||
        (filters.hub_assigned === 'false'
          ? row.assigned.text === 'Unassigned' // Show only unassigned
          : row.assigned.text !== 'Unassigned')) && // Show only assigned
      (filters.software_version === '' ||
        filters.software_version === 'All' ||
        row.software_version
          ?.toLowerCase()
          .includes(filters.software_version.toLowerCase())) &&
      (selectedFacility === '' || row.facility_name === selectedFacility) &&
      (filters.hub_status === '' || row.status.text === filters.hub_status);

    return matchesSearchTerm && matchesFilters;
  });

  const FacilityOptions = useMemo<FacilityOption[]>(() => {
    // Default option
    const defaultOption: FacilityOption = {
      label: 'All Facilities',
      value: '',
    };

    if (!rows?.length) {
      return [defaultOption];
    }

    // Use Map to ensure uniqueness
    const facilityMap = new Map<string, FacilityOption>();

    rows.forEach((row) => {
      if (row.facility_name) {
        facilityMap.set(row.facility_name, {
          label: row.facility_name,
          value: row.facility_name,
        });
      }
    });

    // Convert Map to array and sort
    const sortedFacilities = Array.from(facilityMap.values()).sort((a, b) =>
      a.label.localeCompare(b.label)
    );

    return [defaultOption, ...sortedFacilities];
  }, [rows]);
  return (
    <Box display="flex" flexDirection="column" bgcolor={lightBackground1}>
      <Box flexGrow={1} p={4}>
        <Box pb={4}>
          <Typography>Hubs</Typography>
        </Box>

        <Box display="flex" gap={2}>
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Typography variant="caption">Facility</Typography>
            <StyledSelect
              label="Facility"
              value={selectedFacility}
              onChange={handleFacilityChange}
              useNewStyles
            >
              {FacilityOptions.map((op: any) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.icon && <span style={{ marginRight: 8 }}>{op.icon}</span>}
                  {op.label}
                </MenuItem>
              ))}
            </StyledSelect>
          </Box>

          <Box display="flex" flexDirection="column" flexGrow={2}>
            <Typography variant="caption">Search</Typography>
            <TextField
              placeholder="Search"
              value={searchTerm}
              onChange={handleSearchChange}
              fullWidth
              variant="outlined"
            />
          </Box>

          <Box display="flex" flexDirection="column" justifyContent="flex-end">
            <Button
              variant="outlined"
              color="secondary"
              startIcon={<FontAwesomeIcon icon={faBarsFilter} />}
              onClick={() => setIsFilterModalOpen(true)}
            />
          </Box>
        </Box>
        <DataGridTable
          rows={filteredData || []}
          columns={columns}
          loading={isDeviceListLoading}
        />
      </Box>
      {isFilterModalOpen ? (
        <NewModal
          showCloseX
          title="Filter"
          onClose={() => {
            setIsFilterModalOpen(false);
            setFilters({
              hub_assigned: '',
              hub_status: '',
              software_version: '',
            });
            if (formRef.current) {
              formRef.current.resetForm();
            }
          }}
          actions={{
            primaryAction: { onClick: handleContinue },
          }}
        >
          <CustomerHubsFilterForm
            formRef={formRef}
            onSubmit={onSubmitFilterForm}
            initialValues={filters}
            rows={rows || []}
          />
        </NewModal>
      ) : null}
    </Box>
  );
};

export default CustomerHubOverview;
