import React from 'react';
import {
  faUser,
  faSignalBarsGood,
  faTriangleExclamation,
  faBuildings,
  faBadgeCheck,
  faBell,
} from '@fortawesome/pro-duotone-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import HomeIcon from '../../../images/icons/HomeIcon';
import CareGiverIcon from '../../../images/icons/CareGiverIcon';
import HubIcon from '../../../images/icons/HubIcon';
import { UserRoles } from '../../constants';
import { SvgIconProps } from '@mui/material';

export interface MenuItem {
  icon: React.ReactElement<SvgIconProps>;
  text: string;
  to: string;
}

export interface BottomMenuItem extends MenuItem {
  visibleFor: UserRoles[];
  alertCount?: number;
}

export const mainMenuConfig = {
  [UserRoles.FACILITY_ADMIN]: {
    heading: 'People',
    topSlice: 2,
    items: [
      { icon: <HomeIcon />, text: 'Home', to: '/' },
      { icon: <HubIcon />, text: 'Hubs', to: '/v2/hubs' },
      {
        icon: <FontAwesomeIcon icon={faUser} size="lg" />,
        text: 'Hub owners',
        to: '/v2/owners',
      },
      { icon: <CareGiverIcon />, text: 'Caregivers', to: '/v2/caregivers' },
    ],
  },
  [UserRoles.CUSTOMER_CARE]: {
    heading: 'Users',
    topSlice: 4,
    items: [
      { icon: <HomeIcon />, text: 'Home', to: '/' },
      { icon: <HubIcon />, text: 'Hubs', to: '/v2/hubs' },
      {
        icon: <FontAwesomeIcon icon={faSignalBarsGood} size="lg" />,
        text: 'Adherence',
        to: '/adherence',
      },
      {
        icon: <FontAwesomeIcon icon={faTriangleExclamation} size="lg" />,
        text: 'Issues',
        to: '/issues',
      },
      {
        icon: (
          <FontAwesomeIcon icon={faBuildings} size="lg" flip="horizontal" />
        ),
        text: 'Facilities',
        to: '/v2/facilities',
      },
      {
        icon: <FontAwesomeIcon icon={faUser} size="lg" />,
        text: 'Hub owners',
        to: '/v2/owner',
      },
      {
        icon: <CareGiverIcon />,
        text: 'Caregivers',
        to: '/v2/caregiver',
      },
      {
        icon: <FontAwesomeIcon icon={faBadgeCheck} size="lg" />,
        text: 'Team',
        to: '/team',
      },
    ],
  },
};

export const bottomMenuConfig: BottomMenuItem = {
  icon: <FontAwesomeIcon icon={faBell} size="lg" />,
  text: 'Alerts',
  to: '/alerts',
  visibleFor: [UserRoles.CUSTOMER_CARE],
  alertCount: 4,
};
