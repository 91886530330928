import axios from 'axios';
import { toast } from 'react-toastify';
import {
  CognitoIdentityClient,
  GetIdCommand,
  GetCredentialsForIdentityCommand,
  Credentials,
} from '@aws-sdk/client-cognito-identity';
import { mqtt, iot, io } from 'aws-iot-device-sdk-v2';
import {
  updateDeviceStatus,
  updateSelectedDeviceStatus,
} from '../store/devices/actionCreators';
import { populateCustomers } from '../store/customers/actionCreators';
import { updateFacilityDeviceStatus } from '../store/facility/actionCreators';
import { Owner } from '../store/customers/customerTypes';
import { DashboardUser } from '../store/dashboardUser/dashboardUserTypes';
import { mqttConnected, mqttDisconnected } from '../store/mqtt/actionCreators';
import {
  MQTTStoreActionTypes,
  MQTTStoreAction,
  MQTTStore,
  MQTTConnectionEvent,
} from '../store/mqtt/mqttTypes';
import {
  getJSONOrString,
  formatUrl,
  getCookie,
  deleteToken,
} from '../shared/utils';
import { restHost } from '../apiConfig';

const {
  REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
  REACT_APP_AWS_COGNITO_USER_POOL_ID,
  REACT_APP_MQTT_CLIENT_PREFIX,
} = process.env;
let publishMessageQueue: Array<any> = [];

async function getDashboardUser(): Promise<DashboardUser | undefined> {
  try {
    const res = await axios.get(formatUrl(restHost, 'customer'), {
      headers: {
        Authorization: `Bearer ${getCookie('access_token')}`,
      },
    });
    return res.data;
  } catch (err: any) {
    console.error(err);
    if (err.response?.status === 401) {
      deleteToken();
    }
    toast.error(err.response?.data?.message);
  }
}

async function attachIoTPolicy(identity_id: string) {
  const params = {
    identity_id,
  };
  try {
    await axios.put(
      formatUrl(restHost, 'auth/identity/attach_iot_policy'),
      params,
      {
        headers: {
          Authorization: `Bearer ${getCookie('access_token')}`,
        },
      }
    );
  } catch (err: any) {
    console.error(err);
    if (err.response?.status === 401) {
      deleteToken();
    }
    toast.error(err.response?.data?.message);
  }
}

// uses id token to get a Cognito identity for MQTT authentication
async function getIdentity(region: string, token: string) {
  const client = new CognitoIdentityClient({ region });
  const loginsParam: any = {
    Logins: {
      [`cognito-idp.${region}.amazonaws.com/${REACT_APP_AWS_COGNITO_USER_POOL_ID}`]:
        token,
    },
  };
  const getIdCommand = new GetIdCommand({
    IdentityPoolId: REACT_APP_AWS_COGNITO_IDENTITY_POOL_ID,
    ...loginsParam,
  });
  const identity = await client.send(getIdCommand);
  const command = new GetCredentialsForIdentityCommand({
    IdentityId: identity.IdentityId,
    ...loginsParam,
  });
  return client.send(command);
}

// Creates and returns a MQTT connection with identity creds
function buildMQTTConnection(
  endpoint: string,
  region: string,
  credentials: Credentials,
  clientId: string
) {
  const config_builder =
    iot.AwsIotMqttConnectionConfigBuilder.new_builder_for_websocket();
  config_builder.with_endpoint(endpoint);
  config_builder.with_client_id(clientId);
  config_builder.with_clean_session(true);
  config_builder.with_credentials(
    region,
    credentials.AccessKeyId ?? '',
    credentials.SecretKey ?? '',
    credentials.SessionToken ?? ''
  );
  config_builder.with_keep_alive_seconds(30);
  const config = config_builder.build();

  const clientBootstrap = new io.ClientBootstrap();
  const client = new mqtt.MqttClient(clientBootstrap);
  return client.new_connection(config);
}

const mqttMiddleware = () => {
  let connection: mqtt.MqttClientConnection | null = null;

  const handleConnectionStatusesUpdate = (
    store: MQTTStore,
    payload: MQTTConnectionEvent
  ) => {
    const {
      customers: { facilityCustomers },
      devices: { selectedDevice },
    } = store.getState();
    const { clientId, eventType } = payload;

    if (facilityCustomers && clientId.startsWith('mobile-')) {
      store.dispatch(
        populateCustomers(
          (facilityCustomers || []).map((customer: Owner) => {
            const updatedCustomer =
              customer.customer_id === Number(clientId.split('-').pop());
            if (updatedCustomer) {
              return {
                ...customer,
                is_online: eventType === 'connected',
              };
            }
            return customer;
          })
        )
      );
    }

    const connStatus = eventType === 'connected' ? 'online' : 'offline';
    store.dispatch(updateDeviceStatus(clientId, connStatus));
    store.dispatch(updateFacilityDeviceStatus(clientId, connStatus));
    if (selectedDevice && selectedDevice.serial_number === clientId) {
      store.dispatch(updateSelectedDeviceStatus(connStatus));
    }
    return;
  };

  const onMQTTMessageReceived = (store: MQTTStore) => {
    return (
      topic: string,
      payload: ArrayBuffer,
      dup: boolean,
      _: mqtt.QoS,
      __: boolean
    ) => {
      if (dup) return;
      let message = getJSONOrString(
        new TextDecoder('utf8').decode(new Uint8Array(payload))
      );

      // Regex pattern to match both "connected" and "disconnected" topics
      const connectionTopicPattern =
        /^\$aws\/events\/presence\/(connected|disconnected)\/[^/]+$/;
      if (connectionTopicPattern.test(topic)) {
        handleConnectionStatusesUpdate(store, message);
      } else {
        console.log(
          `MQTT message received in topic: ${topic}\n${JSON.stringify(message)}`
        );
      }
    };
  };

  // the middleware part of this function
  return (store: MQTTStore) =>
    (next: any) =>
    async (action: MQTTStoreAction) => {
      if (!action) {
        return console.log('mqtt action was null?');
      }
      switch (action.type) {
        case MQTTStoreActionTypes.MQTT_CONNECT: {
          const { host, token } = action.payload;
          if (connection !== null) {
            await connection.disconnect();
          }

          if (!host) {
            console.error('MQTT_CONNECT: host must be provided');
            break;
          }

          if (!token) {
            console.error('MQTT_CONNECT: id token must be provided');
            break;
          }

          // connect to the remote host
          console.log('opening mqtt connection');
          try {
            const awsRegion = host.split('.')[2];
            const identity = await getIdentity(awsRegion, token);
            await attachIoTPolicy(identity.IdentityId ?? '');

            const dashboardUser = await getDashboardUser();
            const mqttClientId = `${REACT_APP_MQTT_CLIENT_PREFIX}${dashboardUser?.customer_id}`;
            connection = buildMQTTConnection(
              host,
              awsRegion,
              identity.Credentials ?? {},
              mqttClientId
            );

            connection.on('connect', async () => {
              store.dispatch(mqttConnected());

              if (publishMessageQueue.length) {
                console.log('processing through mqtt publish queue');
                await Promise.all(
                  publishMessageQueue.map((entry) => {
                    return connection?.publish(
                      entry.topic,
                      entry.msg,
                      mqtt.QoS.AtMostOnce
                    );
                  })
                );
                publishMessageQueue = [];
              }
            });

            connection.on('disconnect', () => {
              store.dispatch(mqttDisconnected());
            });

            connection.on('message', onMQTTMessageReceived(store));

            connection.on('connection_failure', (err) => {
              console.error('MQTT connection failed', err);
            });

            connection.on('error', (err) => {
              console.error('MQTT connection error', err);
            });

            await connection.connect();
            const topicSubscriptions = [
              '$aws/events/presence/connected/#',
              '$aws/events/presence/disconnected/#',
              `omcare/customers/${mqttClientId}/#`,
            ];
            await Promise.all(
              topicSubscriptions.map((topic) =>
                connection?.subscribe(topic, mqtt.QoS.AtMostOnce)
              )
            );
          } catch (err) {
            console.error('Error with mqtt connection: ', err);
            return next(action);
          }

          break;
        }
        case MQTTStoreActionTypes.MQTT_DISCONNECT: {
          if (connection !== null) {
            await connection.disconnect();
          }
          connection = null;
          console.log('mqtt connection closed');
          break;
        }
        case MQTTStoreActionTypes.MQTT_PUBLISH: {
          const { topic, msg } = action.payload;
          console.log(
            `publishing to mqtt topic: "${topic}"\nmessage: ${JSON.stringify(
              msg
            )}`
          );
          if (!connection) {
            publishMessageQueue.push({ topic, msg });
            return;
          }
          await connection.publish(
            topic,
            JSON.stringify(msg),
            mqtt.QoS.AtMostOnce
          );
          break;
        }
        default:
          return next(action);
      }
    };
};

export default mqttMiddleware();
