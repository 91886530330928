import { Field, Form, Formik, FormikProps } from 'formik';
import React, { useMemo } from 'react';
import * as yup from 'yup';
import SelectFormField from '../../Shared/SelectFormField';
import { Box } from '@mui/material';
import { Status } from '../../../shared/constants';

const schema = yup.object({
  hub_status: yup.string(),
  hub_assigned: yup.string(),
  software_version: yup.string(),
});

interface HubsFilterFormProps {
  formRef: React.RefObject<
    FormikProps<{
      hub_assigned: string;
      hub_status: string;
      software_version: string;
    }>
  >;
  onSubmit: (values: any) => void;
  initialValues: {
    hub_assigned: string;
    hub_status: string;
    software_version: string;
  };
  rows: any[];
}

const CustomerHubFilters: React.FC<HubsFilterFormProps> = ({
  formRef,
  onSubmit,
  initialValues,
  rows,
}) => {
  const HubStatus = useMemo(() => {
    return [
      { label: Status.Online, value: Status.Online },
      { label: Status.Offline, value: Status.Offline },
    ];
  }, []);

  const HubAssigned = useMemo(() => {
    return [
      { label: 'True', value: 'true' },
      { label: 'False', value: 'false' },
    ];
  }, []);

  const SoftwareVersionOptions = useMemo(() => {
    const options = [{ label: 'All Versions', value: 'All' }];

    if (!rows?.length) return options;

    const uniqueVersions = [
      ...new Set(
        rows
          .filter((row) => row.software_version)
          .map((row) => row.software_version)
      ),
    ];

    return [
      ...options,
      ...uniqueVersions.map((version) => ({
        label: version,
        value: version,
      })),
    ];
  }, [rows]);

  return (
    <Box>
      <Formik
        innerRef={formRef}
        validationSchema={schema}
        onSubmit={onSubmit}
        initialValues={initialValues}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Box display="flex" flexDirection="column" gap={2}>
              <Field
                label="Hub status"
                name="hub_status"
                placeholder="Select hub status"
                component={SelectFormField}
                options={HubStatus}
                useNewStyles
              />
              <Field
                label="Hub assigned"
                name="hub_assigned"
                placeholder="Select to apply"
                component={SelectFormField}
                options={HubAssigned}
                useNewStyles
              />
              <Field
                label="Software version"
                name="software_version"
                placeholder="Select to apply"
                component={SelectFormField}
                options={SoftwareVersionOptions}
                useNewStyles
              />
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CustomerHubFilters;
